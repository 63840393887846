.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    margin-top: 50px;
}

.inputGroup {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    padding: 0 80px;
}

.inputGroup input::placeholder {
    opacity: 0.5;
}

.inputFields {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 50px;
}

.error {
    font-size: 12px;
    margin-top: 8px;
    background-color: #f31a1a1e;
    padding: 3px;
    margin-left: 2px;
    border-radius: 3px;
    border: 1px solid red;
}

.errorIcons {
    margin-right: 3px;
}

.errorMessage {
    margin-top: 1px;
}

.inputFields input[type="text"], .inputFields input[type="email"], .inputFields input[type="number"] {
    border: 1px solid #80808071;
    padding: 5px;
    outline: 0;
    border-radius: 7px
}

.radioGroup{
    display: flex;
    width: 100%;
}

.radioGroup input[type="radio"] {
    display: none;
}

.radioGroup label {
    padding-left: 20px;
}


.radioGroup input[type="radio"] + label {
    display: inline-block;
    position: relative;
    border: 1px solid black;
    height: 20px;
    width: 20px;
    left: 10px;
}

.radioGroup input[type="radio"]:checked + label {
    background-color: #8c15ba;
}



.radioGroup input[type="radio"] {
    display: none;
}

.radioGroup input[type="radio"] + label {
    display: inline-block;
    position: relative;
    border: 2px solid #5a15ba;
    height: 20px;
    width: 20px;
}

.radioGroup input[type="radio"]:checked + label::after {
    position: absolute;
    content: "*";
    top: 0px;
    left: 8px;
    right: 0;
    bottom: 0;
    margin: auto;
    border-bottom-color: transparent;
    border-top-color: transparent;
    color: #fff;
}

.inputFields label {
    font-size: 12px;
    margin-bottom: 5px;
}

.inputLabel::after {
    content: "*";
    color: red;
}