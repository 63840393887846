body {
  margin: 0;
  padding: 0;
}

.searchIcon {
  cursor: pointer;
  position: relative;
  right: 35px;
  bottom: 3px;
}

